// Charge la base commune
import './common.js'

// Scripts librairies
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// rafraîchissement
function r () {
  Waypoint.refreshAll()
}

// OwlCarousel
async function getOwlCarousel () {
  import(/* webpackChunkName: "owlCarousel" */ './js/components/owlCarousel.js').then(() => {
    r()
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion des carousel
  var $d = $('.owl-carousel.c')
  if ($d.length) {
    $d.waypoint(function () {
      getOwlCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    r()
  })
})
